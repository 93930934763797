import React, { useState } from "react"
import Modal from "./Modal"
import styled from "styled-components"
import { toastSuccess, toastError } from "../utils/myToasts"
import { changePasswordPromise } from "../utils/CallAPIs"
import PasswordField from "./PasswordField"

import lockLogo from "../imgs/lock.png"
import closeLogo from "../imgs/close.png"

const ModalContainer = styled.div`
  .modal-heading-3 {
    color: rgb(25, 35, 50);
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: 0px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .modal-close-div {
    top: 12px;
    right: 12px;
    cursor: pointer;
    position: absolute;
    user-select: none;
    display: block;
    box-sizing: border-box;
  }

  .modal-close-div-child {
    display: block;
    width: 31px;
    height: 31px;
    min-width: 31px;
    min-height: 31px;
    fill: #586271;
    position: relative;
    user-select: none;
    box-sizing: border-box;
  }

  .modal-close-img {
    width: 31px;
    height: 31px;
    min-width: 31px;
    min-height: 31px;
  }

  .modal-body {
    width: 80%;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .modal-body-form {
    padding-top: 33px;
    width: 100%;
    margin: 0 auto;
  }

  .password-requirements-item {
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    display: flex;
  }

  .password-requirements-item-icon {
    fill: rgb(155, 161, 170);
    color: rgb(155, 161, 170);
    transition: fill 0.25s cubic-bezier(0.8, 0.3, 0.45, 0.95),
      color 0.25s cubic-bezier(0.8, 0.3, 0.45, 0.95);
    margin-right: 2px;
    display: block;
    width: 31px;
    height: 31px;
    min-width: 31px;
    min-height: 31px;
    fill: #586271;
    position: relative;
    user-select: none;
  }

  .password-requirements-item-icon-img {
    user-select: none;
    width: 31px;
    height: 31px;
    padding: 5px;
    display: block;
  }

  .password-requirements-item-para {
    color: rgb(155, 161, 170);
    transition: color 0.25s cubic-bezier(0.8, 0.3, 0.45, 0.95);
    white-space: pre;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 960px) {
      font-size: 12px;
    }
  }
`

const MyNewPasswordSaveBtn = styled.button`
  width: 100%;
  margin: 0;
  margin-top: 15px;
  fill: #ffffff;
  color: "#ffffff";
  box-shadow: none;
  background-color: ${props => (props.newPasswordOkay ? "#667AF4" : "#e9ebed")};
  border: 0;
  display: inline-block;
  padding: 11px 20px 10px;
  outline: none !important;
  position: relative;
  font-size: 12px;
  text-align: center;
  transition: box-shadow 0.25s cubic-bezier(0.8, 0.3, 0.45, 0.95),
    color 0.25s cubic-bezier(0.8, 0.3, 0.45, 0.95),
    background-color 0.25s cubic-bezier(0.8, 0.3, 0.45, 0.95),
    border 0.25s cubic-bezier(0.8, 0.3, 0.45, 0.95);
  user-select: none;
  font-weight: 700;
  line-height: 14px;
  white-space: pre;
  border-radius: 32px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  text-decoration: none;
  overflow: visible;
  box-sizing: border-box;

  .save-password-span {
    display: inherit;
    box-sizing: border-box;
    fill: #ffffff;
    color: #ffffff;
    font-size: 12px;
    text-align: center;
    font-weight: 700;
    line-height: 14px;
    white-space: pre;
    text-transform: uppercase;
    letter-spacing: 0.6px;
  }
`

export default function NewPasswordModal(props) {
  const [newPasswordText, setNewPasswordText] = useState("")
  const [newPasswordOkay, setNewPasswordOkay] = useState(false)

  const { isOpen, toggleModal } = props

  async function changePassword(e) {
    e.preventDefault()
    try {
      await changePasswordPromise(newPasswordText)
      toastSuccess("Password changed successfully!")
    } catch (err) {
      toastError("Password change failed.")
      console.error(err)
    }
  }

  const handleClose = () => {
    toggleModal()
    setNewPasswordText("")
    setNewPasswordOkay(false)
  }

  return (
    <Modal show={isOpen} onHide={handleClose} onEscapeKeydown={handleClose}>
      <ModalContainer>
        <h3 className="modal-heading-3">New Password</h3>
        <div className="modal-close-div">
          <div
            role="button"
            tabIndex="0"
            className="modal-close-div-child"
            onClick={toggleModal}
            onKeyDown={toggleModal}
          >
            <img
              className="modal-close-img"
              src={closeLogo}
              alt="close modal icon"
            />
          </div>
        </div>
        <div className="modal-body">
          <form className="modal-body-form" onSubmit={changePassword}>
            <div>
              <section style={{ marginTop: "37px" }}>
                <PasswordField
                  setPasswordText={setNewPasswordText}
                  setPasswordOkay={setNewPasswordOkay}
                  placeholderText="Create a new password"
                />
                {[
                  "At least 8 characters",
                  "One number or special character (!@#$%^&*)",
                  "One or more uppercase letters (A-Z)",
                  "One or more lowercase letters (a-z)",
                ].map(item => (
                  <div key={item} className="password-requirements-item">
                    <div className="password-requirements-item-icon">
                      <img
                        className="password-requirements-item-icon-img"
                        src={lockLogo}
                        alt="password-requirements-item-icon-img"
                      />
                    </div>
                    <p className="password-requirements-item-para">{item}</p>
                  </div>
                ))}
              </section>
            </div>
            <div className="save-password-div">
              <MyNewPasswordSaveBtn newPasswordOkay={newPasswordOkay}>
                <span className="save-password-span">save new password</span>
              </MyNewPasswordSaveBtn>
            </div>
          </form>
        </div>
      </ModalContainer>
    </Modal>
  )
}
