import React, { useState } from "react"
import styled from "styled-components"

import closeImg from "../imgs/close.png"
import { getPasswordError } from "../pages/createaccount"

const MyPasswordField = styled.div`
  margin-bottom: 15px;
  display: block;
  box-sizing: border-box;
`

const PasswordContainer = styled.div`
  position: relative;
  box-sizing: border-box;
`

const PasswordInput = styled.input`
  color: #586271;
  box-shadow: 0 1px 3px 0 rgba(88, 98, 113, 0.06);
  border-color: rgba(25, 35, 50, 0.1);
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : "rgba(25, 35, 50, 0.05)"};
  width: 100%;
  border: 1px solid transparent;
  outline: none !important;
  padding: 9.5px 20px;
  font-size: 16px;
  transition: all 0.15s cubic-bezier(0.8, 0.3, 0.45, 0.95);
  font-family: GT Walsheim, SF UI Display, Helvetica, sans-serif;
  line-height: 24px;
  border-radius: 6px;
  letter-spacing: 0.2px;
  box-sizing: border-box;
`

const PasswordShowHideDiv = styled.div`
  transform-origin: 50% 50% 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  top: 0;
  right: 14px;
  width: auto;
  height: 100%;
  position: absolute;
  display: block;
  user-select: none;
  box-sizing: border-box;
`

const PasswordShowHidePara = styled.p`
  color: rgb(155, 161, 170);
  top: 50%;
  right: 0;
  display: block;
  position: absolute;
  transform: translateY(-50%);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
  padding: 0;
  white-space: normal;
  box-sizing: border-box;
`

const ErrorDiv = styled.div`
  opacity: 1;
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
`

const ErrorIcon = styled.div`
  fill: rgb(255, 99, 99);
  color: rgb(255, 99, 99);
  display: block;
  width: 31px;
  height: 31px;
  min-width: 31px;
  min-height: 31px;
  fill: #586271;
  position: relative;
  user-select: none;
  box-sizing: border-box;
`

const ErrorImg = styled.img`
  width: 24px;
  height: 24px;
  display: block;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 50%;
  background: rgb(255, 131, 123);
`

const ErrorPara = styled.p`
  color: rgb(255, 99, 99);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  margin: auto 0;
  padding-bottom: 2px;
  font-weight: normal;
  white-space: normal;
  box-sizing: border-box;
`

export default function PasswordField({
  setPasswordText,
  setPasswordOkay,
  placeholderText,
  backgroundColor,
}) {
  const [inputType, setInputType] = useState("password")
  const [showHideTxt, setShowHideTxt] = useState("show")
  const [errorTxt, setErrorTxt] = useState("")

  const showHideClickHandler = () => {
    if (showHideTxt === "show") {
      setShowHideTxt("hide")
      setInputType("text")
    } else if (showHideTxt === "hide") {
      setShowHideTxt("show")
      setInputType("password")
    }
  }

  const showErrorHandler = e => {
    const error = getPasswordError(e.target.value)

    setErrorTxt(error)
    setPasswordOkay(!error)
    setPasswordText(e.target.value)
  }

  return (
    <MyPasswordField>
      <PasswordContainer>
        <PasswordInput
          type={inputType}
          onKeyUp={showErrorHandler}
          onBlur={showErrorHandler}
          placeholder={placeholderText}
          backgroundColor={backgroundColor}
        />
        <PasswordShowHideDiv>
          <PasswordShowHidePara onClick={showHideClickHandler}>
            {showHideTxt}
          </PasswordShowHidePara>
        </PasswordShowHideDiv>
      </PasswordContainer>
      {errorTxt ? (
        <span>
          <ErrorDiv>
            <ErrorIcon>
              <ErrorImg src={closeImg} alt="error icon" />
            </ErrorIcon>
            <ErrorPara>{errorTxt}</ErrorPara>
          </ErrorDiv>
        </span>
      ) : null}
    </MyPasswordField>
  )
}
