import React, { useState } from "react"
import styled from "styled-components"
import { get } from "lodash"

import NewPasswordModal from "./NewPasswordModal"

import lockLogo from "../imgs/lock.png"
import editLogo from "../imgs/edit.png"

import authStore from "../stores/auth"
import api from "../utils/api"

const MyDesktopPersonalInfo = styled.section`
  margin-bottm: 42px;

  .payment-heading {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.2;
    letter-spacing: 0.2px;

    &:first-child {
      margin-top: 0;
    }
  }

  .password-div,
  .name-div {
    margin-bottom: 30px;
  }

  .name-div-1 {
    position: relative;
  }

  .name-input {
    width: 100%;
    color: #586271;
    border: 1px solid transparent;
    outline: none !important;
    padding: 9.5px 20px;
    font-size: 16px;
    transition: all 0.15s cubic-bezier(0.8, 0.3, 0.45, 0.95);
    line-height: 24px;
    border-radius: 6px;
    letter-spacing: 0.2px;
    border-color: rgba(25, 35, 50, 0.1);
    background-color: #f0f0f0;
    box-sizing: border-box;
  }

  .name-input:hover {
    box-shadow: 0 1px 3px 0 rgba(88, 98, 113, 0.08);
    border-color: rgba(25, 35, 50, 0.2);
  }

  .name-input:focus {
    box-shadow: 0 1px 4px 0 rgba(88, 98, 113, 0.08);
    border-color: rgba(25, 35, 50, 0.2);
  }

  .lock-span {
    box-sizing: border-box;
  }
  .name-lock-div {
    top: 0;
    right: 8px;
    width: 31px;
    height: 100%;
    position: absolute;
  }

  .name-lock-div-1 {
    top: 50%;
    right: 0;
    position: absolute;
    transform: translateY(-50%);
    display: block;
    width: 31px;
    height: 31px;
    min-width: 31px;
    min-height: 31px;
  }

  .lock-logo {
    display: block;
    width: 31px;
    height: 31px;
    padding: 5px;
    box-sizing: border-box;
  }

  .edit-profile-button-common {
    width: 100%;
    box-shadow: 0 0 0 1px hsla(216, 33.3%, 14.7%, 0.1),
      0 1px 3px 0 hsla(216, 12.4%, 39.4%, 0.06);
    line-height: 14px;
    white-space: pre;
    border-radius: 32px;
    letter-spacing: 0.6px;
    text-decoration: none;
    border: 0;
    display: inline-block;
    padding: 15px 20px;
    margin-top: 4px;
    position: relative;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
  }

  .edit-profile-button-fixed {
    background-color: #667af4;
    color: #ffffff;
  }

  .password-div {
    border: 1px solid rgba(25, 35, 50, 0.1);
    padding: 8px 9px;
    box-shadow: 0 1px 3px 0 rgba(88, 98, 113, 0.06);
    transition: border-color 0.25s cubic-bezier(0.8, 0.3, 0.45, 0.95),
      box-shadow 0.25s cubic-bezier(0.8, 0.3, 0.45, 0.95);
    border-radius: 6px;
    background-color: #ffffff;
    box-sizing: border-box;
  }

  .pd-flex {
    height: 32px;
    padding-left: 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    border-radius: 6px;
    box-sizing: border-box;
    outline: none;
  }

  .pd-flex:hover {
    background-color: rgba(25, 35, 50, 0.05);
  }

  .hidden-pass-para {
  }

  .hidden-pass-edit {
    min-width: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 31px;
    height: 31px;
    min-width: 31px;
    min-height: 31px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    user-select: none;
    transition: background-color 0.25s cubic-bezier(0.8, 0.3, 0.45, 0.95),
      box-shadow 0.25s cubic-bezier(0.8, 0.3, 0.45, 0.95);
  }

  .hpe1 {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
  }

  .hpe1a {
    display: block;
    width: 31px;
    height: 31px;
    min-width: 31px;
    min-height: 31px;
    fill: #586271;
    position: relative;
    user-select: none;
  }

  .green {
    background: #6abf83;
  }

  .api-key {
    cursor: pointer;
    background: white;
  }

  a {
    color: #667af4;
  }
`

async function downloadCSV(e) {
  e.preventDefault()
  const url = "/account/export_active_subscriptions"
  const { headers, data } = await api.get(url, undefined, {
    responseType: "blob",
  })
  const type = headers["content-type"]
  const downloadUrl = window.URL.createObjectURL(new Blob([data], { type }))
  const link = document.createElement("a")

  link.href = downloadUrl
  link.setAttribute("download", "proxies.csv")
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export default function PersonalInfoSection() {
  const email = get(authStore, "state.user.email", "email not found")
  const userID = get(authStore, "state.user.user_id", null)
  const user = get(authStore, "state.user")
  const [isOpen, setIsOpen] = useState(false)
  const [showApi, setShowApi] = useState(false)

  function toggleModal() {
    setIsOpen(!isOpen)
  }

  return (
    <MyDesktopPersonalInfo>
      <h4 className="payment-heading">Customer ID</h4>
      <div className="name-div">
        <div className="name-div-1">
          <input
            className="name-input"
            disabled={true}
            placeholder={userID}
            value={userID}
          />

          <span className="lock-span">
            <div className="name-lock-div">
              <div className="name-lock-div-1">
                <img className="lock-logo" src={lockLogo} alt="lock-logo" />
              </div>
            </div>
          </span>
        </div>
      </div>
      <h4 className="payment-heading">Email</h4>
      <div className="name-div">
        <div className="name-div-1">
          <input className="name-input" disabled={true} placeholder={email} />

          <span className="lock-span">
            <div className="name-lock-div">
              <div className="name-lock-div-1">
                <img className="lock-logo" src={lockLogo} alt="lock-logo" />
              </div>
            </div>
          </span>
        </div>
      </div>
      <h4 className="payment-heading">Password</h4>
      <div className="password-div">
        <div
          role="button"
          tabIndex="0"
          className="pd-flex"
          onClick={toggleModal}
          onKeyDown={toggleModal}
        >
          <p className="hidden-pass-para">*********</p>
          <div className="hidden-pass-edit">
            <div className="hpe-1">
              <div className="hpe-1a">
                <img className="lock-logo" src={editLogo} alt="edit-logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <h4 className="payment-heading">API Key</h4>
      <div className="name-div">
        <div
          data-tip={showApi ? null : "Click to reveal API key"}
          data-effect="solid"
          className="name-div-1"
          onClick={() => !showApi && setShowApi(true)}
        >
          <input
            disabled
            className="name-input api-key"
            type={showApi ? "text" : "password"}
            placeholder="API Key"
            value={get(user, "api_key", "API Key not found")}
          />

          {!showApi && (
            <span className="lock-span">
              <div className="name-lock-div">
                <div className="name-lock-div-1">
                  <img className="lock-logo" src={lockLogo} alt="lock-logo" />
                </div>
              </div>
            </span>
          )}
        </div>
        {showApi && (
          <p>
            Learn more from our{" "}
            <a href="https://api.mountproxies.com/public_docs">API Docs</a>
          </p>
        )}
      </div>
      <h4 className="payment-heading">Proxy List</h4>
      <div className="name-div">
        <p>
          <a href="#" onClick={downloadCSV}>
            Click here
          </a>{" "}
          to download a list of all proxies and credentials as csv.
        </p>
      </div>

      <NewPasswordModal
        isOpen={isOpen}
        toggleModal={toggleModal}
      ></NewPasswordModal>
    </MyDesktopPersonalInfo>
  )
}
