import React from "react"

import PersonalInfoSection from "../../components/PersonalInfoSection"

import Layout from "./components/_Layout"

function Account() {
  return (
    <Layout name="Settings" title="Change your profile and account settings">
      <PersonalInfoSection />
    </Layout>
  )
}

export default Account
